import React from "react"
import enGB from "./locales/en-GB.json";
import deDE from "./locales/de-DE.json";

export const tokenRequest = {
  audience: `https://staging-mrisense-api.herokuapp.com/`,
  scope: "read:customers"
};

export const ContextUser = React.createContext([{}, () => { }]);

let locale = "en";
export const localeText = locale === "de" ? deDE : enGB;


export const getUserMetadata = async (getAccessTokenSilently) => {
  try {
    const accessToken = await getAccessTokenSilently({
      audience: `https://staging-mrisense-api.herokuapp.com/`,
      scope: "read:customers"
    });
    return accessToken;
  } catch (e) {
    console.log(e.message);
  }
};
