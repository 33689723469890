import React, { useEffect, useState } from "react";
import {
  Layout,
  Table,
  Tag,
  Space,
  Button,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import semver from "semver";
import { loadDevices } from "./client";
import { useAuth0 } from "@auth0/auth0-react";
import { tokenRequest } from "./utils"
import { useParams } from "react-router-dom";

const { Content } = Layout;
const latestFirmwareVersion = "2.1.0";

export const Devices = () => {
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently, user } = useAuth0();
  const [devices, setdevices] = useState([]);
  let { customerId } = useParams();

  useEffect(() => {
    const loadAPI = async () => {
      let token = await getAccessTokenSilently(tokenRequest)
      const data = await loadDevices(token, customerId)
      setLoading(false);
      setdevices(
        data.map((x, index) => ({
          key: index,
          serial: x.id,
          version: x.version,
        }))
      );
    }
    loadAPI();
  }, [getAccessTokenSilently, user?.sub, customerId]);

  function confirm(e) {
    message.success("Device unlinked from your account");
  }

  const columns = [
    {
      title: "Serial",
      dataIndex: "serial",
      key: "serial",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      render: (version) => {
        let isOld = semver.lt(version, latestFirmwareVersion);
        let color = "green";
        let message = "Up to date";
        if (isOld) {
          color = "red";
          message = "Needs updating";
        }
        return (
          <Tooltip title={message}>
            <Tag color={color} key={version}>
              {version}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure unlink this device from your account?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
            disabled
          >
            <Button type="primary" disabled>Unlink</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Table columns={columns} dataSource={devices} loading={loading} />
    </Content>
  );
};
