import React, { useEffect, useState } from "react";
import {
  Layout,
  Table,
  Tag,
  Space,
  Button,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import { loadJourneys, deleteJourney } from "./client";
import { useAuth0 } from "@auth0/auth0-react";
import { tokenRequest } from "./utils"
import {
  Link,
  useParams
} from "react-router-dom";

const { Content } = Layout;

export const Journeys = (props) => {
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently, user } = useAuth0();
  const [journeys, setjourneys] = useState([]);
  let { customerId } = useParams();

  useEffect(() => {
    const loadAPI = async () => {
      let token = await getAccessTokenSilently(tokenRequest)
      const data = await loadJourneys(token, customerId)
      setLoading(false);
      setjourneys(
        data.map((x) => ({
          key: x.id,
          name: x.name,
          created: x.timestamp,
          updated: "",
          tags: { serial: x.device, reference: x.reference },
        }))
      );
    }
    loadAPI();
  }, [getAccessTokenSilently, user?.sub, customerId]);

  async function confirm(journeyId) {
    let token = await getAccessTokenSilently(tokenRequest)
    const data = await deleteJourney(token, customerId, journeyId)
    setjourneys(
      data.map((x) => ({
        key: x.id,
        name: x.name,
        created: x.timestamp,
        updated: "",
        tags: { serial: x.device, reference: x.reference },
      })));
    message.success("Journey deleted from your account");
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Updated",
      dataIndex: "updated",
      key: "updated",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {Object.keys(tags).map((key) => {
            let color = "black";
            if (key === "serial") {
              color = "geekblue";
            }
            if (key === "reference") {
              color = "green";
            }
            if (!tags[key]) {
              return <></>;
            }
            return (
              <Tooltip title={key}>
                <Tag color={color} key={tags[key]}>
                  {tags[key]}
                </Tag>
              </Tooltip>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
          >
            <Link to={`/customers/${customerId}/journeys/${record.key}`} >View</Link>
          </Button>

          <Popconfirm
            title="Are you sure delete this journey?"
            onConfirm={() => confirm(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="ghost">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Table columns={columns} dataSource={journeys} loading={loading} />
    </Content>
  );
};
