import React from "react";
import { Space, Popconfirm, Button, Layout, Table, DatePicker, TimePicker } from "antd";
import moment from "moment";

const { Content } = Layout;

function transformData(data) {
  data = data.map((x, index) => {
    return {
      id: x.id,
      key: index,
      index: x.index,
      time: moment(`${x.timestamp}`),
      battery: `${x.battery} %`,
      reading:
        x.helium > 100
          ? `${x.helium} Liters`
          : x.helium > 0
            ? `${x.helium} %`
            : x.core_temp_0 > 0 || x.core_temp_1 > 0
              ? `${x.core_temp_0} K, ${x.core_temp_1} K`
              : "",
      temperature: `${x.ambient_temperature} C`,
      coordinates: `${x.latitude} ${x.longitude}`,
    };
  });
  console.log(data)
  return data;
}
export const JourneyTable = (props) => {
  const data = transformData(props.data);

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text, record) => (
        <Space size="middle">
          <DatePicker value={record.time} showTime />
          {/* <TimePicker value={record} /> */}

        </Space>
      ),
    },
    {
      title: "Battery",
      dataIndex: "battery",
      key: "battery",
    },
    {
      title: "Reading",
      dataIndex: "reading",
      key: "reading",
    },
    {
      title: "Ambient",
      dataIndex: "temperature",
      key: "temperature",
    },
    {
      title: "Coordinates",
      dataIndex: "coordinates",
      key: "coordinates",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure delete this result?"
            onConfirm={() => props.onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="ghost" >Delete</Button>
          </Popconfirm>
        </Space>
      ),
    }
  ];

  return (
    <Content>
      <Table columns={columns} dataSource={data} />
    </Content>
  );
};
