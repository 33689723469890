import { Empty, Layout } from "antd";
const { Content } = Layout;

export const JourneyOverview = (props) => {
  console.log(props)
  const reading = props.data
    .filter((x) => x.helium > 0 || x.core_temp_0 > 0 || x.core_temp_1 > 0)
    .at(0);
  const battery = props.data.filter((x) => x.battery > 0).at(0)?.battery;
  const newestData = props.data.at(0);
  const lastSeenSeconds =
    new Date() - Date.parse(`${newestData?.date}T${newestData?.time}`);
  const lastSeenDays = lastSeenSeconds / 3600000 / 24;
  const lastSeenHours = (lastSeenDays - Math.floor(lastSeenDays)) * 24;
  const batteryColor = battery < 30 ? "red" : "green";
  const coreTemperatureColor = (temperature) =>
    temperature > 4 ? "red" : "green";
  if (props.data.length === 0) {
    return <Empty></Empty>;
  }
  return (
    <Content>
      {reading?.helium > 0 && reading?.helium <= 100 && (
        <h2>Helium: {reading?.helium} %</h2>
      )}
      {reading?.helium > 100 && <h2>Helium: {reading?.helium} Liters</h2>}
      {reading?.core_temp_0 + reading?.core_temp_1 > 0 && (
        <div>
          <h2>
            Core Temperature:{" "}
            <span style={{ color: coreTemperatureColor(reading.core_temp_0) }}>
              {reading?.core_temp_0} Kelvin
            </span>
          </h2>
          <h2>
            Core Temperature:{" "}
            <span style={{ color: coreTemperatureColor(reading.core_temp_1) }}>
              {reading.core_temp_1} Kelvin
            </span>
          </h2>
        </div>
      )}
      <h2>
        Battery: <span style={{ color: batteryColor }}>{battery} %</span>
      </h2>
      <h2>
        Last Seen: {Math.floor(lastSeenDays)} days {Math.floor(lastSeenHours)}{" "}
        hours{" "}
      </h2>
    </Content>
  );
};
