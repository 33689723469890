import React from "react";
import { Layout } from "antd";
const { Header } = Layout;

export const GlobalHeader = () => {

    return (<Header style={{ padding: 0, display: "flex", flexDirection: "row" }}>
        <div
            style={{
                flexGrow: "2",
            }}
        ></div>
        <h1
            style={{
                textAlign: "center",
                color: "wheat",
                flexGrow: "1",
            }}
        >
            MRISense Portal
        </h1>
        <div
            style={{
                textAlign: "end",
                color: "wheat",
                flexGrow: "1",
                paddingLeft: "10px",
            }}
        >
            {/* <a onClick={() => console.log("en")}>EN</a>/ */}
            {/* <a onClick={() => console.log("de")}>DE</a> */}
        </div>
    </Header>
    )
}
