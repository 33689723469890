// const apiUrl = "https://staging-mrisense-api.herokuapp.com/"; //"https://mri-tracker.herokuapp.com/"; //"http://localhost:5001/";
const apiUrl = process.env.REACT_APP_API_URL;
let user = {}
let customer = ""

const fetchOrRedirectIfUnauthorized = async (input, init = null) => {
  try {
    let result = await fetch(input, init);
    return result;
  } catch (err) {
    if (err instanceof TypeError) {
      throw err
    }
    console.log(typeof err);
    console.log(err);
    window.location.pathname = "/login";
  }
};

function getHeadersWithAuth(token) {
  let headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Content-Type", "application/json")
  return headers;
}

export const loadCustomers = async (token) => {
  const data = await fetchOrRedirectIfUnauthorized(apiUrl + "customers", {
    headers: getHeadersWithAuth(token),
  });
  return await data.json();
};

export const setCustomer = (selectedCustomer) => {
  customer = selectedCustomer
  console.log(customer)
}

export const loadDevices = async (token, customerId) => {
  const devicesData = await fetchOrRedirectIfUnauthorized(apiUrl + "devices?customer_id=" + customerId,
    {
      headers: getHeadersWithAuth(token),
    });
  return await devicesData.json();
};

export const loadJourneys = async (token, customerId) => {
  const journeysData = await fetchOrRedirectIfUnauthorized(
    apiUrl + "journeys?customer_id=" + customerId,
    {
      headers: getHeadersWithAuth(token),
    }
  );
  return await journeysData.json();
};

export const newDevice = async (id) => {
  await fetchOrRedirectIfUnauthorized(apiUrl + "devices?customer_id=" + customer, {
    headers: getHeadersWithAuth(user.username, user.password),
    method: "POST",
    body: JSON.stringify({ id }),
  });
};

export const newJourney = async (token, customerId, name, reference, device) => {
  await fetchOrRedirectIfUnauthorized(apiUrl + "journeys?customer_id=" + customerId, {
    headers: getHeadersWithAuth(token),
    method: "POST",
    body: JSON.stringify({ device, name, reference }),
  });
};

export const loadJourney = async (token, customerId, journeyId) => {
  const data = await fetchOrRedirectIfUnauthorized(
    apiUrl + "journeys/" + journeyId + "?customer_id=" + customerId,
    {
      headers: getHeadersWithAuth(token),
    }
  );
  return await data.json();
};

export const updateJourney = async (token, customerId, journeyId, name, reference) => {
  await fetchOrRedirectIfUnauthorized(
    apiUrl + "journeys/" + journeyId + "?customer_id=" + customerId,
    {
      method: "PUT",
      headers: getHeadersWithAuth(token),
      body: JSON.stringify({ name, reference }),
    }
  );
};

export const deleteJourney = async (token, customerId, journeyId) => {
  const data = await fetchOrRedirectIfUnauthorized(
    apiUrl + "journeys/" + journeyId + "?customer_id=" + customerId,
    {
      method: "DELETE",
      headers: getHeadersWithAuth(token),
    });
  return await data.json();
};

export const loadResults = async (token, customerId, journeyId) => {
  const data = await fetchOrRedirectIfUnauthorized(
    apiUrl + "results/" + journeyId + "?customer_id=" + customerId,
    {
      headers: getHeadersWithAuth(token),
    }
  );
  return await data.json();
};

export const deleteResult = async (token, customerId, journey, index) => {
  const exec = apiUrl + "results/" + journey + "/" + index + "?customer_id=" + customerId;
  const data = fetchOrRedirectIfUnauthorized(exec, {
    method: "DELETE",
    headers: getHeadersWithAuth(token),
  });
  return await data.json();
};
