import React, { useEffect, useState } from "react";
import {
  Layout,
  Form, Input, Button, Select,
  Popconfirm,
  message,
} from "antd";
import { loadDevices, newJourney } from "./client";
import { useAuth0 } from "@auth0/auth0-react";
import { tokenRequest } from "./utils"
import {
  Link,
  useParams
} from "react-router-dom";

const { Option } = Select;

const { Content } = Layout;

export const Tools = (props) => {
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently, user } = useAuth0();
  const [devices, setdevices] = useState([]);
  let { customerId } = useParams();

  useEffect(() => {
    const loadAPI = async () => {
      let token = await getAccessTokenSilently(tokenRequest)
      const data = await loadDevices(token, customerId)
      setLoading(false);
      setdevices(
        data.map((x, index) => ({
          serial: x.id,
        }))
      );
    }
    loadAPI();
  }, [getAccessTokenSilently, user?.sub, customerId]);

  const onFinish = async (values) => {
    console.log('Success:', values);
    let token = await getAccessTokenSilently(tokenRequest)
    newJourney(token, customerId, values.name, values.reference, values.device)
    message.success("Journey added to your account");
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error("Journey not added");
  };

  return (
    <Content>
      <Form
        name="journey"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input journey name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Reference"
          name="reference"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Device"
          name="device"
          rules={[{ required: true, message: 'Please choose a device!' }]}
        >
          <Select defaultValue="" style={{ width: 120 }} >
            {devices.map(device => {
              return <Option value={device.serial}>{device.serial}</Option>
            })
            }
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Content>
  );
};
