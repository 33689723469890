import React from "react";
import { GlobalHeader } from "./header";
import { Button, Layout } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

const { Content } = Layout;

export const LoginForm = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <>
      <Layout>
        <GlobalHeader></GlobalHeader>
        <Content style={{ padding: "20px" }}>
          {!isAuthenticated && (
            <Button type="primary" onClick={() => loginWithRedirect()}>
              Login
            </Button>)}
        </Content>
      </Layout>
    </>
  );
};
