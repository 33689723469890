import "./journeyMap.css";
import { useRef, useEffect } from "react";
import { Button, Layout } from "antd";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
const { Content } = Layout;
mapboxgl.accessToken =
  "pk.eyJ1IjoiYnJhbmRvbjIyNTVwIiwiYSI6ImNrbmo5N3FjMzB2b2oybnBnMmpweGd0ZzIifQ.-vSRXHCRzT3p2VyWS6-NDA";

export const JourneyMap = (props) => {
  let reading = props.data.filter((x) => x.longitude > 0).at(0);
  if (!reading) {
    reading = {}
  }
  reading.longitude = reading?.longitude ?? 0;
  reading.latitude = reading?.latitude ?? 0;
  const lineStringData = props.data
    .filter((x) => x.longitude > 0)
    .map((x) => [x.longitude, x.latitude]);
  const mapContainer = useRef(null);
  const map = useRef(null);

  let animatedPoint = {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Point",
        coordinates: [reading?.longitude ?? 0, reading?.latitude ?? 0],
      },
    },
  };
  let animationIndex = 0;
  function animateMarker() {
    if (animationIndex >= lineStringData.length) {
      animationIndex = 0;
    }
    map.current.panTo(lineStringData[animationIndex]);
    map.current.getSource("animated").setData({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: lineStringData[animationIndex],
          },
        },
      ],
    });
    animationIndex = animationIndex + 1;
    // Request the next frame of the animation.
    // requestAnimationFrame(animateMarker);
  }

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [reading.longitude, reading.latitude],
      zoom: 13,
    });
    map.current.on("load", () => {
      console.log("A load event occurred.");
      map.current.addControl(new mapboxgl.NavigationControl());
      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: lineStringData,
          },
        },
      });
      map.current.addSource("animated", animatedPoint);
      lineStringData.map((x) => {
        new mapboxgl.Marker().setLngLat(x).addTo(map.current);
        return undefined;
      });
      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#03ff00",
          "line-width": 8,
        },
      });
      map.current.addLayer({
        id: "animated",
        source: "animated",
        type: "circle",
        paint: {
          "circle-radius": 10,
          "circle-color": "#ff1100",
        },
      });
    });
  });

  return (
    <Content>
      <h1>Map</h1>
      <Button onClick={animateMarker}>Move</Button>
      <div ref={mapContainer} className="map-container" />
    </Content>
  );
};
