import React from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { tokenRequest } from "./utils"
import { Layout, Table, Space, message, Button } from "antd";
import { loadCustomers } from "./client";
import { Link } from "react-router-dom";

const { Content } = Layout;

export const Customers = (props) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAPI = async () => {
      let token = await getAccessTokenSilently(tokenRequest)
      const customers = await loadCustomers(token)
      setLoading(false);
      const data = customers.map((customer) => {
        return {
          key: customer.id,
          name: customer.name
        }
      })
      setCustomers(data)
    }
    loadAPI();
  }, [getAccessTokenSilently, user?.sub]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/customers/${record.key}/journeys`} onClick={() => props.onSelectCustomer(record.key)}>Use</Link>
        </Space >
      ),
    },
  ];

  return (
    <Content>
      <Table columns={columns} dataSource={customers} loading={loading} />
    </Content>
  );
};
