import React, { useEffect, useState } from "react";
import {
  Layout,
  Form, Input, Button, Select,
  Popconfirm,
  message,
} from "antd";
import { updateJourney } from "./client";
import { useAuth0 } from "@auth0/auth0-react";
import { tokenRequest } from "./utils"
import {
  useParams
} from "react-router-dom";

const { Option } = Select;

const { Content } = Layout;

export const JourneyEdit = (props) => {
  const { getAccessTokenSilently, user } = useAuth0();
  let { customerId } = useParams();
  const joruneyId = props.data.id;
  const name = props.data.name;
  const reference = props.data.reference;
  const onFinish = async (values) => {
    console.log('Success:', values);
    let token = await getAccessTokenSilently(tokenRequest)
    await updateJourney(token, customerId, joruneyId, values.name, values.reference)
    message.success("Journey updated");
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error("Journey not updated");
  };

  return (
    <Content>
      <Form
        name="journey"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input journey name!' }]}
          initialValue={name}
        >
          <Input
          />
        </Form.Item>

        <Form.Item
          label="Reference"
          name="reference"
          initialValue={reference}
        >
          <Input
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Content>
  );
};
