import React, { useEffect, useState } from "react";
import { Layout, Tabs } from "antd";
import { JourneyTable } from "./JourneyTable";
import { JourneyOverview } from "./JourneyOverview";
import { JourneyGraphAmbient } from "./JourneyGraphAmbient";
import { JourneyGraphCore } from "./JourneyGraphCore";
import { JourneyMap } from "./JourneyMap";
import { deleteResult } from "./client";
import { useAuth0 } from "@auth0/auth0-react";
import { tokenRequest } from "./utils"
import { loadJourney, loadResults } from './client'
import { useParams } from "react-router-dom";
import { JourneyEdit } from "./JourneyEdit";

const { Content } = Layout;
const { TabPane } = Tabs;

export const Journey = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [journey, setJourney] = useState({});
  const [results, setResults] = useState([]);
  const [token, setToken] = useState(null);
  let { customerId, journeyId } = useParams();

  useEffect(() => {
    const loadAPI = async () => {
      let token = await getAccessTokenSilently(tokenRequest)
      setToken(token);
      const journeyData = await loadJourney(token, customerId, journeyId);
      const resultData = await loadResults(token, customerId, journeyId);
      setJourney(journeyData);
      setResults(resultData);
    }
    loadAPI();
  }, [getAccessTokenSilently, user?.sub, customerId, journeyId]);

  if (journey && results) {
    return (
      <Content>
        <h2>{journey?.name}</h2>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Overview" key="1">
            <JourneyOverview data={results}> </JourneyOverview>
          </TabPane>
          <TabPane tab="Edit" key="2">
            <JourneyEdit data={journey}> </JourneyEdit>
          </TabPane>
          <TabPane tab="Table" key="3">
            <JourneyTable data={results} onDelete={async (id) => {
              console.log(journey.id, id)
              let data = await deleteResult(token, customerId, journey.id, id)
              setResults(data);
            }}></JourneyTable>
          </TabPane>
          <TabPane tab="Map" key="4">
            <JourneyMap data={results}></JourneyMap>
          </TabPane>
          <TabPane tab="Graph - Ambient Temperature" key="5">
            <JourneyGraphAmbient data={results}></JourneyGraphAmbient>
          </TabPane>
          {/* <TabPane tab="Graph - Core" key="5">
            <JourneyGraphCore data={results}></JourneyGraphCore>
          </TabPane> */}
        </Tabs>
      </Content>
    );
  }
  return <div>Loading ...</div>;
};
