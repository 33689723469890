import React from "react";
import { Layout, Table, Space, Button, Popconfirm, message } from "antd";

const { Content } = Layout;
const data = [
  {
    key: "1",
    name: "Toby",
    email: "toby@mrisense.com",
  },
  {
    key: "2",
    name: "Brandon",
    email: "brandon@mrisense.com",
  },
];

export const Users = () => {
  function confirm(e) {
    message.success("User removed from account");
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure unlink this user from your account?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Remove</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Table columns={columns} dataSource={data} />
    </Content>
  );
};
