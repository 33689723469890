import React from "react";
import { Content } from "antd/lib/layout/layout";
import { localeText } from "./utils";
export const Home = () => {
  return (
    <Content>
      <h2>{localeText.home.pageHeading}</h2>
      <h3>{localeText.home.pageAddDeviceSubHeading}</h3>
      <p>{localeText.home.pageAddDeviceContent}</p>
      <h3>{localeText.home.pageGetDataHeader}</h3>
      <p>{localeText.home.pageGetDataContent}</p>
    </Content>
  );
};
