import React, { useState } from "react";
import { InputNumber } from "antd";
import { Line } from "@ant-design/charts";
import moment from "moment";

function transformAmbientTemperatureData(data) {
  return data
    .map((x) => {
      return {
        time: `${x.date}T${x.time}`,
        temperature: x.ambient_temperature,
      };
    })
    .filter((x) => x.temperature > -50);
}
function transformHeliumLevelData(data) {
  return data
    .filter((x) => x.helium > 0)
    .map((x) => {
      return {
        time: `${x.date}T${x.time}`,
        helium: x.helium,
      };
    });
}

function transformCoreTemperatureData(data) {
  return data
    .filter((x) => x.core_temp_0 > 0 || x.core_temp_1 > 0)
    .flatMap((x) => {
      return [
        {
          time: `${x.date}T${x.time}`,
          temperature: x.core_temp_0,
          type: "1",
        },
        {
          time: `${x.date}T${x.time}`,
          temperature: x.core_temp_1,
          type: "2",
        },
      ];
    });
}
export const JourneyGraphCore = (props) => {
  const [showLastDays, setshowLastDays] = useState(30);
  const recentData = props.data.filter(
    (x) => moment().diff(moment(x.date, "YYYY/MM/DD"), "days") < showLastDays
  );
  const ambientTemperatureData = transformAmbientTemperatureData(recentData);
  const coreTemperatureData = transformCoreTemperatureData(recentData);
  console.log(coreTemperatureData)
  const heliumLevelData = transformHeliumLevelData(recentData);

  const ambientTemperatureConfig = {
    data: ambientTemperatureData,
    xField: "time",
    yField: "temperature",
    xAxis: {
      type: "time",
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "30"],
        end: ["max", "100"],
        color: "#F4664A",
      },
    ],
  };

  const coreTemperatureConfig = {
    data: coreTemperatureData,
    xField: "time",
    yField: "temperature",
    seriesField: "type",
    xAxis: {
      type: "time",
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "3"],
        end: ["max", "500"],
        color: "#F4664A",
      },
    ],
  };

  const heliumConfig = {
    data: heliumLevelData,
    xField: "time",
    yField: "helium",
    xAxis: {
      type: "time",
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
  };

  return (
    <div>
      <InputNumber
        addonBefore="Show"
        addonAfter="days"
        defaultValue={30}
        onChange={setshowLastDays}
      />

      {coreTemperatureData.length > 0 && (
        <div>
          <h2>Helium Level</h2>

          <Line style={{ maxHeight: 200 }} {...heliumConfig} />
        </div>
      )}
      {coreTemperatureData.length > 0 && (
        <div>
          <h2>Core Temperature</h2>

          <Line style={{ maxHeight: 200 }} {...coreTemperatureConfig} />
        </div>
      )}
    </div>
  );
};
