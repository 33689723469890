import React, { useState } from "react";
import { Layout, Menu, PageHeader } from "antd";
import { DesktopOutlined, FileOutlined, TeamOutlined, GatewayOutlined } from "@ant-design/icons";
import "./App.css";
import { localeText } from "./utils";
import { GlobalHeader } from "./header"
import { LoginForm } from "./LoginForm";
import { Home } from "./Home";
import { Journeys } from "./Journeys";
import { Devices } from "./Devices";
import { Users } from "./Users";
import { Customers } from "./Customers";
import { Journey } from "./Journey";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Tools } from "./Tools";

const { Content, Footer, Sider } = Layout;

function App() {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const [collapsed, setCollapsed] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [context, setContext] = useState({});

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return (
    <>
      {!isAuthenticated && <LoginForm></LoginForm>}
      {isAuthenticated &&
        <Layout style={{ minHeight: "100vh" }}>

          <GlobalHeader >
          </GlobalHeader>

          <Layout className="site-layout">
            <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
              <div className="logo" />
              <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
                <Menu.Item
                  key="1"
                  icon={<DesktopOutlined />}
                >
                  <Link to="/">{localeText.home.title}</Link>
                </Menu.Item>
                {customerId &&
                  <Menu.Item
                    key="2"
                    icon={<DesktopOutlined />}
                  >
                    <Link to={`/customers/${customerId}/journeys`}>{localeText.journeys.title}</Link>
                  </Menu.Item>
                }
                {customerId &&
                  <Menu.Item
                    key="3"
                    icon={<FileOutlined />}
                  >
                    <Link to={`/customers/${customerId}/devices`}>{localeText.devices.title}</Link>
                  </Menu.Item>
                }
                {customerId &&
                  <Menu.Item
                    key="4"
                    icon={<GatewayOutlined />}
                  >
                    <Link to={`/customers/${customerId}/tools`}>{localeText.tools.title}</Link>
                  </Menu.Item>
                }
                {/* {customerId &&
                  <Menu.Item
                    key="4"
                    icon={<TeamOutlined />}
                  >
                    <Link to={`/customers/${customerId}/users`}>{localeText.users.title}</Link>
                  </Menu.Item>
                } */}
                <Menu.Item
                  key="5"
                  icon={<TeamOutlined />}
                >
                  <Link to="/customers">{localeText.customers.title}</Link>
                </Menu.Item>
                <Menu.Item
                  key="6"
                  icon={<TeamOutlined />}
                  onClick={() => logout()}
                >
                  {localeText.word.logout}
                </Menu.Item>

              </Menu>
            </Sider>
            <Content style={{ margin: "0 16px" }}>
              <PageHeader
                className="site-page-header"
                title={localeText.home.title}
                subTitle={localeText.home.subtitle}
              />
              <div style={{ padding: 24, minHeight: 360 }}>
                <Routes>
                  <Route path="/" element={<Home></Home>}>
                  </Route>
                  <Route path="/customers/:customerId/devices" element={<Devices></Devices>}>
                  </Route>
                  <Route path="/customers/:customerId/journeys" element={<Journeys
                    onViewJourney={(id) => {
                      setContext({ id: id });
                    }}
                  ></Journeys>}>
                  </Route>
                  <Route path="/customers/:customerId/tools" element={<Tools></Tools>}>
                  </Route>
                  <Route path="/customers/:customerId/users" element={<Users />}>
                  </Route>
                  <Route path="/customers" element={<Customers onSelectCustomer={(customerId) => setCustomerId(customerId)} />}>
                  </Route>
                  <Route path="/customers/:customerId/journeys/:journeyId" element={<Journey {...context}></Journey>}>
                  </Route>
                </Routes>
              </div>
            </Content>
          </Layout>
          <Footer style={{ textAlign: "center" }}>MRISense ©2021</Footer>
        </Layout>
      }
    </>
  );
}

export default App;
